.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 90px;
	color: #01CC7F;
}

.cards-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
    margin-top: 42px;
    padding-bottom: 10px;
	width: calc(100% + 25px);
	// height: 310px;
	// max-width: 100vw;
	// overflow-x: auto;


	@media only screen and (min-width: 800px) {
		min-width: 625px;
		justify-content: flex-start;
	}

	.card {
		width: 274px;
		height: 305px;
		border-radius: 16px;
		background: #ffffff;
		padding-bottom: 32px;
        margin-right: 25px;

		cursor: pointer;

		.top-card {
			position: relative;
			text-align: center;
			color: white;
			height: 131px;

			/* imagem degradê preto */
			
			// background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(.png);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			// background-attachment: fixed;

			// height: 100%;
			filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));

			/* mask */
			// background: #ffffff;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
			border-radius: 16px 16px 0px 0px;

			// .card-bg-img {
			// 	height: 100%;
			// 	width: 100%;
			// 	border-radius: 16px 16px 0px 0px;
			// }

			.top-card-content {
				display: flex;
				flex-direction: column;
				margin: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-right: -50%;
				transform: translate(-50%, -50%);

				.logo {
					height: 43px;
					margin-bottom: 16px;
				}
				span {
					font-family: "Open Sans", sans-serif;
					// font-family: Open Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 120%;

					display: flex;
					align-items: center;
					text-align: center;

					color: #ffffff;
				}
			}
		}

		.card-body {
			padding: 29px 12px;
			display: flex;
			flex-direction: column;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
			background-color: transparent;
			border-radius: 16px;

			span,
			strong {
				font-family: "Montserrat", sans-serif;
				font-size: 12px;
				display: flex;
				align-items: center;
				text-align: center;

				color: #7a7a7a;
			}

			strong {
				color: #212121;
			}

			.info-1 {
				/* Infos */
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 0px;

				// position: absolute;
				width: 246px;
				height: 12px;
				left: 14px;
				top: 165px;

				.icon-div {
					/* icon ativos de artes 1 */
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0px;

					/* Icons */
					svg {
						height: 16px;
						width: 16px;
					}

					svg path {
						fill: #212121;
					}

					span {
						margin-left: 8px;
					}
				}
			}

			.info-2 {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;

				div {
					display: flex;
					flex-direction: column;
				}

				div:last-child {
					align-items: flex-end;
				}

				span,
				strong {
					margin-bottom: 11.99px;
				}
			}
		}
	}
}