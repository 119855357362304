.page-header {
	position: fixed;
	z-index: 60;

	width: 100%;
	height: 40px;
	// left: calc(50% - 375px / 2);
	top: 0px;

	border-bottom: 1px solid #b7b7b7;
	background: #fff;
	color: #000;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	.button-go-back {
		cursor: pointer;
		border: none;
		background-color: transparent;
		margin-left: 7px;

		@media only screen and (min-width: 900px) {
			margin-left: 90px;
		}
	}

	.title-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		// width: calc(100vw - 85px);

		// @media only screen and (min-width: 900px) {
		// 	width: calc(100vw - 245px);
		// }
		.page-title {
			// margin-right: 48px;
			text-align: center;
			font-family: "Montserrat", sans-serif;
			// font-size: 16px;
		}

		.dude-btn {
			border: none;
			background: transparent;

			padding: 0px;
			.dude-icon {
				height: 17px;
				cursor: pointer;
				margin-top: 5px;
			}
		}
	}
}
