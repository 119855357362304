.home {
    h1 {
        font-size: 16px;
        font-weight: 100;
    }

    .section-1 {
        margin: 0 auto;
        margin-top: 60px;
        width: 85%;
    }
}