.opportunities-container {
	margin-top: 32px;
	margin-bottom: 36px;

	h2 {
		margin-top: 0;
	}

	div {
		.entries-list {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			padding: 0px;

			width: 278px;
			// height: 17px;
			left: 16px;
			top: 40px;

			.option {
				font-size: 16px;
				line-height: 100%;
				/* identical to box height, or 16px */

				display: flex;
				align-items: center;

				color: #9a9a9a;
				text-decoration: none;
				cursor: pointer;
				transition: 0.5s;
			}

			.option:hover {
				font-weight: bold;
			}

			.option.active {
				color: #00bb6d;
				text-decoration-line: underline;
			}
		}

		.scrolls-list {
			/* Filtro carrossel */
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0px;

			max-width: 100vw;
			overflow-x: auto;
			height: 30px;
			// height: 18px;
			// left: 16px;
			top: 0px;

			margin-top: 16px;

			@media only screen and (max-width: 600px) {
				max-width: 100vw;
			}

			.scroll-option {
				/* Obras de Arte */
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 4px 8px;
				cursor: pointer;
				transition: 0.2s;
				// position: static;
				height: 18px;
				color: #9a9a9a;

				/* Primary colors/Primary 0 */
				border: 1px solid #e8e8e8;
				box-sizing: border-box;
				border-radius: 16px;

				/* Inside Auto Layout */
				flex: none;
				order: 0;
				flex-grow: 0;
				margin: 0px 10px;

				/*  ------- text -------- */
				font-size: 10px;
			}

			.scroll-option:hover {
				border-width: 2px;
				font-weight: 600;
			}

			.scroll-option.active {
				color: #00bb6d;
				border-color: #00bb6d;
			}
		}
	}
}
