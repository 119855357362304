.empty-list-message {
	max-width: 400px;
	margin: 0 auto;
	margin-top: 12px;
	margin-bottom: 40px;
	// margin-top: 80px;
	// min-width: 60vw;

	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;

	@media only screen and (max-width: 400px) {
		max-width: 100%;
	}

	img { 
		margin-bottom: 16px;
	}
}