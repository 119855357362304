.answer-container {
	max-width: 700px;
	margin: 0 auto;
	margin-bottom: -24px;

	.triangule-div {
		width: 20px;
		height: 20px;
		// background: white;
		margin-top: 35px;

		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-bottom: -1px;
		z-index: 2;

		.triangule {
			margin-left: 110px;

		// @media only screen and (min-width: 900px) {
			// margin-left: 150px;
		// }

			border-top: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #212222;
			border-left: 10px solid transparent;
		}
	}

	.answer {
		font-size: 11px;
		background-color: #212222;
		// border: 1px solid #707070;
		border-radius: 10px;
		color: #fff;
		padding: 5px 8px;
		width: 80%;
		margin: 0 auto;
	}
}
