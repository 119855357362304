.doubts-container {
    .doubts-text {

        .doubts-title {
            color: #535353;
            margin-top: 32px;
            margin-bottom: 0;
        }
    }

}