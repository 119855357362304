/* ------------- Texts --------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #212121;
	text-align: start;
}

h2 {
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
}

p {
	// font-family: Roboto;
	font-size: 12px;
	line-height: 150%;
	text-align: left;
	color: #454545;
}

a {
	font-size: 12px;
	line-height: 100%;

	color: #01cc7f;
}

.content {
	margin-top: 40px;
	padding: 0 16px;
	height: calc(100vh - 115px);
	overflow-y: auto;
	overflow-x: hidden;

	display: flex;
	flex-direction: column;

	a {
		margin-bottom: 32px;
	}
}

@media only screen and (min-width: 800px) {
	.content {
		margin: 40px 15%;
	}
}

@media only screen and (min-width: 1000px) {
	.content {
		margin: 40px 20%;
	}
}
